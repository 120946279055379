import clsx from "clsx";

type SkeletonProps = {
  className?: string;
  circle?: boolean;
};

const Skeleton = ({ className = "", circle, ...rest }: SkeletonProps) => {
  const commonClasses = "animate-pulse bg-skeleton";
  if (circle) {
    return <div className={clsx("shrink-0 rounded-full", commonClasses, className)} data-testid="Skeleton" {...rest} />;
  }

  return (
    <div className={clsx("h-5 w-24 shrink-0 rounded-md", commonClasses, className)} data-testid="Skeleton" {...rest} />
  );
};

export default Skeleton;
