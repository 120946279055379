import { supabaseUrl } from "utils/supabase";

export const buildSupabasePublicUrl = (path: string) => {
  if (path.startsWith(supabaseUrl as string)) return path;

  return `${supabaseUrl}/storage/v1/object/public/files/${path}`;
};

export const getBlurUrl = () => {
  const keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

  const triplet = (e1: number, e2: number, e3: number) =>
    keyStr.charAt(e1 >> 2) +
    keyStr.charAt(((e1 & 3) << 4) | (e2 >> 4)) +
    keyStr.charAt(((e2 & 15) << 2) | (e3 >> 6)) +
    keyStr.charAt(e3 & 63);

  const rgbDataURL = (r: number, g: number, b: number) =>
    `data:image/gif;base64,R0lGODlhAQABAPAA${
      triplet(0, r, g) + triplet(b, 255, 255)
    }/yH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==`;

  const blurDataURL = rgbDataURL(153, 153, 153);

  return blurDataURL;
};
