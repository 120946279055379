"use client";

import React, { createContext, useCallback, useState, useEffect, useRef } from "react";
import querystring from "query-string";

import { usePathname, useRouter, useSearchParams } from "next/navigation";
import isEqual from "lodash/isEqual";
import toString from "lodash/toString";
import { SortItem } from "types/baTypes";
import {
  FORM_PARAMS_SET,
  RecordItem,
  SIDEBAR_PARAMS_SET,
  FILTERS_PARAMS_SET,
  CELL_SIDE_PARAMS_SET,
  COLLAPSIBLE_VIEWER_PARAMS_SET,
  ActiveSearchContextState
} from "types/common";
import { APP_QUERY_PARAM_TYPES, CellType, FILTER_OPERATOR, SidebarContainer } from "utils/constants";
import { isColumnFileTag } from "utils/dataUtils";
import { getColumnSearchParamName } from "utils/paramsUtils";
import useUIState from "hooks/useUIState";

type PARAMS_SET = {
  filters?: FILTERS_PARAMS_SET[];
  sortItems?: SortItem[];
  searchQuery?: string;
  sidebar?: SIDEBAR_PARAMS_SET;
  form?: FORM_PARAMS_SET;
  expanded?: boolean;
  cellSide?: CELL_SIDE_PARAMS_SET;
  viewSection?: {
    sectionId: string;
    sectionSlug: string;
  };
  excludeParams?: APP_QUERY_PARAM_TYPES[]; // Sometimes we want to exclude certain params from being set while setting other params
  collapsibleViewer?: COLLAPSIBLE_VIEWER_PARAMS_SET;
  multiPageTab?: string;
  clearParamsAfterPush?: string; // Contains a string of APP_QUERY_PARAM_TYPES to clear after pushing the route
};
export interface SearchParamContextState {
  searchParams: RecordItem;
  setParams: ({ filters, sortItems, searchQuery, sidebar, form, expanded, cellSide }: PARAMS_SET) => void;
  clearParams: (type: APP_QUERY_PARAM_TYPES) => void;
  pushRoute: (props: {
    newPath: string;
    removeParams?: APP_QUERY_PARAM_TYPES[];
    addParams?: string;
    dontAddQuery?: boolean;
  }) => void;
  activeSearchContexts?: ActiveSearchContextState[];
  pushActiveSearchContext: (newContext: ActiveSearchContextState) => void;
  removeActiveSearchContext: (contextId: string) => void;
  resetActiveSearchContexts: (newContext?: ActiveSearchContextState) => void;
  updateHiddenSearchContextIds: (contextId?: string) => void;
  hiddenSearchContextIds?: string[];
  currentSearchContextRecordId: string | null;
  updateCurrentSearchContextRecordId: (recordId: string | null) => void;
  currentSearchContextProjectId: string | null;
  updateCurrentSearchContextProjectId: (projectId: string | null) => void;
}

export const SearchParamContext = createContext<SearchParamContextState | null>(null);

const { Provider } = SearchParamContext;

export const SearchParamContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const pathname = usePathname();
  const queryParams = useSearchParams();
  const { updateUIState } = useUIState();
  const [searchParams, setSearchParams] = useState<RecordItem>(
    querystring.parse((queryParams || "").toString(), {
      arrayFormat: "comma"
    })
  );
  const [activeSearchContexts, setActiveSearchContexts] = useState<ActiveSearchContextState[]>([]);
  const [hiddenSearchContextIds, setHiddenSearchContextIds] = useState<string[]>([]);
  const [currentSearchContextRecordId, setCurrentSearchContextRecordId] = useState<string | null>(null); // This will replace currentRecordId in table context
  const [currentSearchContextProjectId, setCurrentSearchContextProjectId] = useState<string | null>(null); // This will replace currentDealId in table context
  const lastPathPushed = useRef<string | null>(null);
  const searchParamsRef = useRef<RecordItem>(searchParams); // Using this to update path so that all params are tracked

  const query = queryParams?.toString();
  const isShowDetailQuery = queryParams?.get("showDetail");
  const isUserTypeQuery = queryParams?.get("user_type");
  const isNestedLevelQuery = queryParams?.get("nLvl") || queryParams?.get("path");

  const getFilterParams = useCallback((filter: FILTERS_PARAMS_SET) => {
    const filterParams: RecordItem = {};
    let filterName = getColumnSearchParamName(filter.column);
    if (filter.filterOperator) {
      filterName = `${filterName}:${filter.filterOperator}`;
    }
    let isFilesTagColumn = false;
    const isColTextArray = !!filter?.column?.isTextArray;
    if (filter.column) {
      isFilesTagColumn = isColumnFileTag(filter.column);
    }
    let filterValue;
    if (Array.isArray(filter.filterValue) && filter.filterValue.length) {
      filterValue = isColTextArray
        ? filter.filterValue
        : filter.filterOperator === FILTER_OPERATOR.IN
          ? filter.filterValue.map((fItem: RecordItem) =>
              isFilesTagColumn && fItem?.record?.tag_id ? fItem?.record?.tag_id : fItem?.record?.id || fItem
            )
          : isFilesTagColumn && (filter.filterValue as RecordItem[])?.[0]?.record?.tag_id
            ? (filter.filterValue as RecordItem[])?.[0]?.record?.tag_id
            : (filter.filterValue as RecordItem[])?.[0]?.record?.id;
      filterValue = filterValue?.join(",");
    } else {
      filterValue =
        isFilesTagColumn && (filter.filterValue as RecordItem)?.record?.tag_id
          ? (filter.filterValue as RecordItem)?.record?.tag_id
          : (filter.filterValue as RecordItem)?.record?.id || filter.filterValue;
    }
    if (filter.filterOperator === FILTER_OPERATOR.EMPTY || filter.filterOperator === FILTER_OPERATOR.NOT_EMPTY) {
      filterParams[filterName] = "null";
    } else {
      filterParams[filterName] = filter.column?.type !== CellType.BOOLEAN ? filterValue || "" : filterValue;
    }
    return filterParams;
  }, []);

  const removeParamFromState = useCallback((paramTypeToRemove: APP_QUERY_PARAM_TYPES, paramState: RecordItem) => {
    const newParams = { ...paramState };

    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.CLEAR_PARAMS) {
      delete newParams[APP_QUERY_PARAM_TYPES.CLEAR_PARAMS];
    }
    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.FORM_PATH || paramTypeToRemove === APP_QUERY_PARAM_TYPES.FORM_ADD) {
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_PATH];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_PAGE_ID];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_PAGEFORMID];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_ACTION];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_ID];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_SIDEBAR];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_EXPANDED];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_PARENTID];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_PARENT_PAGE_ID];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_TITLE];

      delete newParams[APP_QUERY_PARAM_TYPES.FORM_ADD];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_ADD_TAB];
      delete newParams[APP_QUERY_PARAM_TYPES.EXPANDED];
      delete newParams[APP_QUERY_PARAM_TYPES.FORM_TRIGGER];
    }
    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.CELL_SIDEPAGE) {
      delete newParams[APP_QUERY_PARAM_TYPES.CELL_SIDEPAGE];
      delete newParams[APP_QUERY_PARAM_TYPES.CELL_SIDERECORD];
      delete newParams[APP_QUERY_PARAM_TYPES.CELL_TABID];
      delete newParams[APP_QUERY_PARAM_TYPES.CELL_SIDEFILE];
      delete newParams[APP_QUERY_PARAM_TYPES.CELL_SIDETYPE];
      delete newParams[APP_QUERY_PARAM_TYPES.CELL_SIDETITLE];
    }
    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.SIDEBAR_PAGE) {
      delete newParams[APP_QUERY_PARAM_TYPES.SIDEBAR_PAGE];
      delete newParams[APP_QUERY_PARAM_TYPES.SIDEBAR_RECORD];
      delete newParams[APP_QUERY_PARAM_TYPES.SIDEBAR_FILE];
      delete newParams[APP_QUERY_PARAM_TYPES.EXPANDED];
    }
    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.DEFAULT_TAB) {
      delete newParams[APP_QUERY_PARAM_TYPES.DEFAULT_TAB];
    }

    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.MULTIPAGE_TAB) {
      delete newParams[APP_QUERY_PARAM_TYPES.MULTIPAGE_TAB];
    }

    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.FILTERS) {
      Object.keys(newParams).forEach((key) => {
        if (key.startsWith("f_")) {
          delete newParams[key];
        }
      });
      delete newParams[APP_QUERY_PARAM_TYPES.FILTERS];
    }
    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.SORT) {
      Object.keys(newParams).forEach((key) => {
        if (key.startsWith("sort:")) {
          delete newParams[key];
        }
      });
      delete newParams[APP_QUERY_PARAM_TYPES.SORT];
    }
    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.EXPANDED) {
      delete newParams[APP_QUERY_PARAM_TYPES.EXPANDED];
    }
    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.COLLAPSIBLE_VIEWER_REMOVE_ITEM) {
      delete newParams[APP_QUERY_PARAM_TYPES.COLLAPSIBLE_VIEWER_REMOVE_ITEM];
    }

    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.VIEW_SECTION) {
      delete newParams[APP_QUERY_PARAM_TYPES.VIEW_SECTION];
      delete newParams[APP_QUERY_PARAM_TYPES.VIEW_SECTIONSLUG];
    }

    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.SEARCH_QUERY) {
      delete newParams[APP_QUERY_PARAM_TYPES.SEARCH_QUERY];
    }
    if (paramTypeToRemove === APP_QUERY_PARAM_TYPES.SIDEBAR_FILE) {
      delete newParams[APP_QUERY_PARAM_TYPES.SIDEBAR_FILE];
    }
    return newParams;
  }, []);

  const updatePath = useCallback(
    (
      updatedParams: RecordItem,
      excludeParams?: APP_QUERY_PARAM_TYPES[],
      deleteFiltersStartsWith?: string,
      isClearParams?: boolean
    ) => {
      // Relies on Ref because multiple calls are made to setParams at the same time
      // Async nature of useState causes incorrect updates
      let currentSearchParams = searchParamsRef?.current
        ? searchParamsRef.current
        : queryParams
          ? querystring.parse((queryParams || "").toString(), {
              arrayFormat: "comma"
            })
          : {};
      if (currentSearchParams[APP_QUERY_PARAM_TYPES.CLEAR_PARAMS]) {
        const paramsToClear = currentSearchParams[APP_QUERY_PARAM_TYPES.CLEAR_PARAMS].split(",");
        paramsToClear.forEach((param: APP_QUERY_PARAM_TYPES) => {
          currentSearchParams = removeParamFromState(param, currentSearchParams);
        });
        // Also remove the clear params from the updated params
        delete currentSearchParams[APP_QUERY_PARAM_TYPES.CLEAR_PARAMS];
      }
      if (excludeParams?.length) {
        excludeParams.forEach((param) => {
          currentSearchParams = removeParamFromState(param, currentSearchParams);
        });
      }
      if (deleteFiltersStartsWith) {
        // Remove all filter params from current search params
        // filterParams contains all applied filters
        Object.keys(currentSearchParams).forEach((key) => {
          if (key.startsWith(deleteFiltersStartsWith)) {
            delete currentSearchParams[key];
          }
        });
      }
      const finalParams = isClearParams ? updatedParams : { ...currentSearchParams, ...updatedParams };
      searchParamsRef.current = finalParams;
      setSearchParams(() => finalParams);
      const query = querystring.stringify(finalParams, { arrayFormat: "comma" });

      if ((!query && (isShowDetailQuery || isUserTypeQuery)) || isNestedLevelQuery) return; // Don't reset the queries if showDetail, user type or nested view is present

      const hashParams = window.location.hash;
      const pathToPush = `${pathname}${query ? "?" + query : ""}${hashParams ? hashParams : ""}`;

      if (lastPathPushed.current === pathToPush) return;
      //  Using router.push causes Next to trigger a full route change, which is very slow and unnecessary when changing params only
      // This is a suggested alternative for shallow routing with query params
      // https://nextjs.org/docs/app/building-your-application/routing/linking-and-navigating#using-the-native-history-api
      history.replaceState(null, "", pathToPush);
      lastPathPushed.current = pathToPush;
    },
    [
      isNestedLevelQuery,
      isShowDetailQuery,
      isUserTypeQuery,
      pathname,
      searchParamsRef,
      queryParams,
      removeParamFromState
    ]
  );

  const setParams = useCallback(
    ({
      filters,
      sortItems,
      searchQuery,
      sidebar,
      form,
      expanded,
      cellSide,
      viewSection,
      excludeParams,
      collapsibleViewer,
      multiPageTab,
      clearParamsAfterPush
    }: PARAMS_SET) => {
      if (clearParamsAfterPush?.length) {
        const updateParams: RecordItem = {
          [APP_QUERY_PARAM_TYPES.CLEAR_PARAMS]: clearParamsAfterPush
        };
        updatePath(updateParams);
        return;
      }
      if (sidebar?.pageId) {
        const sidebarParams: RecordItem = {
          [APP_QUERY_PARAM_TYPES.SIDEBAR_PAGE]: toString(sidebar.pageId),
          [APP_QUERY_PARAM_TYPES.SIDEBAR_RECORD]: toString(sidebar.recordId)
        };
        if (sidebar.fileColId) {
          sidebarParams[APP_QUERY_PARAM_TYPES.SIDEBAR_FILE] = toString(sidebar.fileColId);
        }
        updatePath(sidebarParams, excludeParams);
        return;
      }

      if (collapsibleViewer?.removeViewerId) {
        const collapsibleViewerParams: RecordItem = {
          [APP_QUERY_PARAM_TYPES.COLLAPSIBLE_VIEWER_REMOVE_ITEM]: toString(collapsibleViewer.removeViewerId)
        };

        updatePath(collapsibleViewerParams, excludeParams);
        return;
      }

      if (viewSection?.sectionId) {
        const viewSectionParams: RecordItem = {
          [APP_QUERY_PARAM_TYPES.VIEW_SECTION]: toString(viewSection.sectionId),
          [APP_QUERY_PARAM_TYPES.VIEW_SECTIONSLUG]: toString(viewSection.sectionSlug)
        };

        updatePath(viewSectionParams, excludeParams);
        return;
      }

      if (form?.tablePath) {
        const formParams: RecordItem = {
          [APP_QUERY_PARAM_TYPES.FORM_PATH]: toString(form.tablePath)
        };
        if (form.action) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_ACTION] = toString(form.action);
        }
        if (form.formId) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_ID] = toString(form.formId);
        }
        if (form.pageFormId) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_PAGEFORMID] = toString(form.pageFormId);
        }
        if (form.pageId) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_PAGE_ID] = toString(form.pageId);
        }
        if (form?.parentRecordId) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_PARENTID] = toString(form.parentRecordId);
        }
        if (form?.parentRecordPageId) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_PARENT_PAGE_ID] = toString(form.parentRecordPageId);
        }
        if (form.add?.addPageId) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_ADD] = toString(form.add?.addPageId);

          if (form.add?.expanded) {
            formParams[APP_QUERY_PARAM_TYPES.FORM_EXPANDED] = "1";
          }

          if (form.add?.tabId) {
            formParams[APP_QUERY_PARAM_TYPES.FORM_ADD_TAB] = toString(form.add?.tabId);
          }
        }
        if (form?.sidebar) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_SIDEBAR] = toString(form.sidebar);
        }

        if (form?.title) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_TITLE] = toString(form.title);
        }

        // When we use a different trigger, the context for current record and project id change, for example through Search
        if (form.trigger) {
          formParams[APP_QUERY_PARAM_TYPES.FORM_TRIGGER] = form.trigger;
        }

        updatePath(formParams, excludeParams);
        return;
      }

      if (filters?.length) {
        let filterParams: RecordItem = {};
        filters?.forEach((filter: FILTERS_PARAMS_SET, index: number) => {
          if (filter.filterOrGroup?.length) {
            const filterOrGroupParams: RecordItem = {};
            const orLabel = `f_or${filter.filterGroupType || index}`;
            filterOrGroupParams[orLabel] = "(";
            filter.filterOrGroup.forEach((filterOrGroupItem: FILTERS_PARAMS_SET, filterOrGroupIndex: number) => {
              const flterParamsSet = getFilterParams(filterOrGroupItem);
              const filterKey = Object.keys(flterParamsSet || {})[0];
              filterOrGroupParams[orLabel] += `${filterKey}.${flterParamsSet[filterKey]}|`;
            });
            filterOrGroupParams[orLabel] += ")";
            filterParams = { ...filterParams, ...filterOrGroupParams };
          } else {
            const filterParamSet = getFilterParams(filter);

            filterParams = { ...filterParams, ...filterParamSet };
          }

          if (index === 0) {
            filterParams[APP_QUERY_PARAM_TYPES.FILTERS] = toString(filter.filterSlug);
          }
        });

        updatePath(filterParams, excludeParams, "f_");
        return;
      }

      if (expanded) {
        updatePath({ [APP_QUERY_PARAM_TYPES.EXPANDED]: "1" }, excludeParams);
        return;
      }

      if (multiPageTab) {
        updatePath({ [APP_QUERY_PARAM_TYPES.MULTIPAGE_TAB]: multiPageTab }, excludeParams);
        return;
      }

      if (cellSide?.pageId) {
        const cellSideParams: RecordItem = {
          [APP_QUERY_PARAM_TYPES.CELL_SIDEPAGE]: toString(cellSide.pageId),
          [APP_QUERY_PARAM_TYPES.CELL_SIDERECORD]: toString(cellSide.recordId)
        };
        if (cellSide?.tabId) {
          cellSideParams[APP_QUERY_PARAM_TYPES.CELL_TABID] = toString(cellSide.tabId);
        }
        if (cellSide.fileColId) {
          cellSideParams[APP_QUERY_PARAM_TYPES.CELL_SIDEFILE] = toString(cellSide.fileColId);
        }

        if (cellSide?.type === SidebarContainer.Collapsible) {
          cellSideParams[APP_QUERY_PARAM_TYPES.CELL_SIDETYPE] = cellSide.type;
        }

        if (cellSide?.title) {
          cellSideParams[APP_QUERY_PARAM_TYPES.CELL_SIDETITLE] = cellSide.title;
        }

        updatePath(cellSideParams, excludeParams);
        return;
      }

      if (sortItems?.length) {
        const sortValuesMap = sortItems?.reduce((acc: RecordItem, sortItem) => {
          acc[`sort:${sortItem.id}`] = sortItem.desc ? "desc" : "asc";
          return acc;
        }, {});
        sortValuesMap[APP_QUERY_PARAM_TYPES.SORT] = sortItems[0].sortSlug;

        updatePath(sortValuesMap, excludeParams, "sort:");
        return;
      }
      if (!searchQuery) return;

      updatePath({ [APP_QUERY_PARAM_TYPES.SEARCH_QUERY]: searchQuery }, excludeParams);
    },
    [getFilterParams, updatePath]
  );

  useEffect(() => {
    const newParams = querystring.parse((queryParams || "").toString(), {
      arrayFormat: "comma"
    });

    if (!isEqual(newParams, searchParams)) {
      setSearchParams(newParams);
    }
  }, [queryParams]);

  // Used to preserve the query params when navigating to a new page
  // Does not use search params, only existing query params
  // @@ addParam is the query param string with key and value added directly
  const pushRoute = useCallback(
    ({
      newPath,
      removeParams,
      addParams,
      dontAddQuery
    }: {
      newPath: string;
      removeParams?: APP_QUERY_PARAM_TYPES[];
      addParams?: string;
      dontAddQuery?: boolean;
    }) => {
      updateUIState({ routeChangeLoading: true });
      if (!query || dontAddQuery) {
        router.push(newPath + (addParams ? `?${addParams}` : ""), { scroll: false });
        return;
      }

      if (removeParams?.length) {
        let newQuery = querystring.parse(query, {
          arrayFormat: "comma"
        });
        removeParams.forEach((param) => {
          newQuery = removeParamFromState(param, newQuery);
        });
        router.push(
          `${newPath}?${querystring.stringify(newQuery, { arrayFormat: "comma" })}${
            addParams ? `${newQuery ? "&" : ""}${addParams}` : ""
          }`,
          { scroll: false }
        );
        return;
      }
      const finalQuery = searchParamsRef.current
        ? querystring.stringify(searchParamsRef.current, { arrayFormat: "comma" })
        : query;
      router.push(`${newPath}?${finalQuery}${addParams ? `${finalQuery ? "&" : ""}${addParams}` : ""}`, {
        scroll: false
      });
    },
    [query, router, removeParamFromState, updateUIState]
  );

  const clearParams = useCallback(
    (type: APP_QUERY_PARAM_TYPES) => {
      const newParams = querystring.parse((queryParams || "").toString(), {
        arrayFormat: "comma"
      });
      // Don't reset state if param is not present
      // Multiple calls leads to race conditions with some calls failing
      if (!newParams?.[type]) return;
      const updatedParams = removeParamFromState(type, newParams);
      searchParamsRef.current = updatedParams;
      updatePath(updatedParams, undefined, undefined, true);
    },
    [queryParams, removeParamFromState, updatePath]
  );

  const pushActiveSearchContext = useCallback((newContext: ActiveSearchContextState) => {
    setActiveSearchContexts((prev) => [...prev, newContext]);
  }, []);

  const removeActiveSearchContext = useCallback((contextId: string) => {
    setActiveSearchContexts((prev) => prev.filter((context) => context.id !== contextId));
  }, []);

  const resetActiveSearchContexts = useCallback((newContext?: ActiveSearchContextState) => {
    const finalContext = newContext ? [newContext] : [];
    setActiveSearchContexts(finalContext);
  }, []);

  const updateHiddenSearchContextIds = useCallback((contextId?: string) => {
    setHiddenSearchContextIds((prev) => (contextId ? [...prev, contextId] : []));
    // We also update the active search contexts to remove any non record id contexts
    if (!contextId) {
      setActiveSearchContexts((prev) => [prev?.[0]]);
    }
  }, []);

  const updateCurrentSearchContextRecordId = useCallback((recordId: string | null) => {
    setCurrentSearchContextRecordId(recordId);
  }, []);

  const updateCurrentSearchContextProjectId = useCallback((projectId: string | null) => {
    setCurrentSearchContextProjectId(projectId);
  }, []);

  useEffect(() => {
    updateUIState({ routeChangeLoading: false });
  }, [pathname, updateUIState]);

  return (
    <Provider
      value={{
        searchParams,
        setParams,
        clearParams,
        pushRoute,
        activeSearchContexts,
        pushActiveSearchContext,
        removeActiveSearchContext,
        resetActiveSearchContexts,
        updateHiddenSearchContextIds,
        hiddenSearchContextIds,
        currentSearchContextRecordId,
        updateCurrentSearchContextRecordId,
        currentSearchContextProjectId,
        updateCurrentSearchContextProjectId
      }}
    >
      {children}
    </Provider>
  );
};
