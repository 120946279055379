"use client";
import clsx from "clsx";
import { LucideProps } from "lucide-react";
import dynamicIconImports from "lucide-react/dynamicIconImports";
import dynamic from "next/dynamic";
import React, { useMemo } from "react";
import ImageComponent from "components/Image";
import { NAVIGATION_ICONS_MAP } from "./utils";

export interface IconProps extends Omit<LucideProps, "ref"> {
  name: keyof typeof dynamicIconImports;
}

const NAVIGATION_ICONS_LIST = Object.keys(NAVIGATION_ICONS_MAP);

const Icon = ({ name, className, ...props }: IconProps) => {
  const isCustomIcon = name.startsWith("icons/");
  const IconComp = useMemo(
    () =>
      isCustomIcon
        ? null
        : NAVIGATION_ICONS_LIST.includes(name)
          ? NAVIGATION_ICONS_MAP[name]
          : dynamic(dynamicIconImports[name as keyof typeof dynamicIconImports], {
              loading: ({ isLoading }) => (isLoading ? <div className={className} /> : null)
            }),
    [name, className, isCustomIcon]
  );

  if (isCustomIcon) {
    return (
      <div className={clsx("relative", className)} {...(props as any)}>
        <ImageComponent src={name} fill alt={`icon-${name}`} />
      </div>
    );
  }
  if (!IconComp) return null;

  return <IconComp className={className} {...(props as LucideProps)} />;
};

export default React.memo(Icon);
