"use client";

import LinearProgress from "@mui/material/LinearProgress";
import useUIState from "hooks/useUIState";

const AppLinearProgress = () => {
  const { uiState } = useUIState();

  if (!uiState?.routeChangeLoading) return null;

  return <LinearProgress className="!fixed left-0 top-0 !z-[100] !h-[10px] w-full !bg-primary-800" />;
};

export default AppLinearProgress;
