import { ReadonlyHeaders } from "next/dist/server/web/spec-extension/adapters/headers";
import { PostgrestError, SupabaseClient } from "@supabase/supabase-js";

import {
  ADMIN_CELLS_PAGE_SLUG,
  ADMIN_COL_TEMPLATES,
  ADMIN_CUSTOM_VIEWS,
  ADMIN_MENUS_PAGE_SLUG,
  ADMIN_SEARCH_TABLE_PAGE_SLUG,
  ADMIN_TABLES_PAGE_SLUG,
  ADMIN_TILES_PAGE_SLUG
} from "app/(authRoutes)/(global)/admin/utils";
import { RecordItem } from "types/common";
import { USER_TYPE, ViewOption } from "./constants";

const LOCAL_URL = process.env.NEXT_PUBLIC_LOCAL_URL;
const IS_DEV_ENV = !process.env.NEXT_PUBLIC_VERCEL_URL;

export const getIsMobileFromHeaders = (headersList: ReadonlyHeaders) => {
  const userAgent = headersList.get("user-agent") || "";
  return !!userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i);
};

export const getPathnameFromHeaders = (headersList: ReadonlyHeaders) => {
  let domain = headersList.get("host") || "";
  if (domain.includes("localhost")) {
    domain = LOCAL_URL || "";
  }
  const fullUrl = headersList.get("x-invoke-url") || "";
  const httpMethod = IS_DEV_ENV ? "" : "https://";
  const pathname = fullUrl.replace(httpMethod, "").replace(domain, "").split("?")[0];

  return pathname || "";
};

export const getQueryParamsFromHeaders = (headersList: ReadonlyHeaders) => {
  let domain = headersList.get("host") || "";
  if (domain.includes("localhost")) {
    domain = LOCAL_URL || "";
  }
  const fullUrl = headersList.get("x-invoke-url") || "";
  const httpMethod = IS_DEV_ENV ? "" : "https://";
  const pathname = fullUrl.replace(httpMethod, "").replace(domain, "");
  const params = pathname.split("?")[1];
  if (!params) return undefined;
  return new URLSearchParams(params);
};

export const isInPathname = (pathname?: string | null) => {
  const isAdminPage = pathname?.startsWith("/admin/") || pathname === "/admin";
  const isTable = pathname?.startsWith("/table/");
  const isReportsPage = pathname?.startsWith("/reports/") || pathname === "/reports";
  const isStaticPage = pathname?.startsWith("/p/") || pathname === "/p";
  const isTilesPage = pathname?.startsWith("/t/") || pathname === "/t";
  const isRecord = pathname?.startsWith("/r/") || pathname === "/r";
  const isComponentExplorerPage = pathname?.startsWith("/component-explorer/") || pathname === "/component-explorer";

  const pathSegments = getPathSegments(pathname);

  const isAdminMenuPage = isAdminPage && pathSegments?.includes("menus");
  const isAdminSearchTable = isAdminPage && pathSegments?.includes("searchTables");
  const isAdminCellsPage = isAdminPage && pathSegments?.includes("cells");
  const isAdminTablesPage = isAdminPage && pathSegments?.includes("tables");
  const isAdminTilesPage = isAdminPage && pathSegments?.includes("tiles");
  const isAdminColumnTemplatesPage = isAdminPage && pathSegments?.includes("column-templates");
  const isAdminViewsPage = isAdminPage && pathSegments?.includes("views");
  const isAdminTilesEditPage = isAdminTilesPage && pathSegments?.length === 3;

  let finalSlug = pathSegments?.[0];
  if (!pathSegments?.length || isAdminPage) {
    if (isAdminTablesPage) {
      finalSlug = ADMIN_TABLES_PAGE_SLUG;
    }
    if (isAdminMenuPage) {
      finalSlug = ADMIN_MENUS_PAGE_SLUG;
    }
    if (isAdminTilesPage) {
      finalSlug = ADMIN_TILES_PAGE_SLUG;
    }
    if (isAdminCellsPage) {
      finalSlug = ADMIN_CELLS_PAGE_SLUG;
    }
    if (isAdminSearchTable) {
      finalSlug = ADMIN_SEARCH_TABLE_PAGE_SLUG;
    }
    if (isAdminColumnTemplatesPage) {
      finalSlug = ADMIN_COL_TEMPLATES;
    }
    if (isAdminViewsPage) {
      finalSlug = ADMIN_CUSTOM_VIEWS;
    }
  }

  if (isTable) {
    finalSlug = pathname?.split("/")[2] || "";
  }

  if (isTable || isRecord || ((isReportsPage || isStaticPage || isTilesPage) && pathSegments.length > 1)) {
    finalSlug = pathSegments[1];
  }

  const showTableHeader = isTable || (isAdminPage && pathSegments.length < 2);

  return {
    isAdminPage,
    isTable,
    isReportsPage,
    isStaticPage,
    isTilesPage,
    isRecord,
    finalSlug,
    isAdminMenuPage,
    isAdminCellsPage,
    isAdminColumnTemplatesPage,
    isAdminSearchTable,
    isAdminTablesPage,
    isAdminTilesPage,
    showTableHeader,
    isComponentExplorerPage,
    isAdminViewsPage,
    isAdminTilesEditPage
  };
};

export const getActiveViewFromPathName = (pathname?: string | null) => {
  const pathSegments = getPathSegments(pathname);
  const { isAdminPage } = isInPathname(pathname);

  if (pathSegments?.includes("grid") && !isAdminPage) {
    return ViewOption.GRID;
  }

  if (pathSegments?.includes("multipages") && !isAdminPage) {
    return ViewOption.MULTI_PAGES;
  }

  if (pathSegments?.includes("board") && !isAdminPage) {
    return ViewOption.BOARD;
  }

  if (pathSegments?.includes("note") && !isAdminPage) {
    return ViewOption.NOTE;
  }

  if (pathSegments?.includes("map") && !isAdminPage) {
    return ViewOption.MAP;
  }

  if (pathSegments?.includes("calendar") && !isAdminPage) {
    return ViewOption.CALENDAR;
  }

  if (pathSegments?.includes("activity") && !isAdminPage) {
    return ViewOption.ACTIVITY;
  }
  if (pathSegments?.includes("matrix") && !isAdminPage) {
    return ViewOption.MATRIX;
  }

  if (pathSegments?.includes("conversation") && !isAdminPage) {
    return ViewOption.CONVERSATION;
  }

  return pathSegments?.includes(ViewOption.CARD) && !isAdminPage ? ViewOption.CARD : ViewOption.GRID;
};

export const getPathSegments = (pathname?: string | null) => {
  const segments = pathname?.split("/") || [];
  segments?.shift();
  return segments;
};

export const isPublicRoute = (pathname?: string | null) => {
  return pathname && ["/tiles"].some((route) => pathname.startsWith(route));
};

export const sendServerErrorLog = async (
  {
    error,
    source,
    message,
    additionalInfo,
    type,
    url
  }: {
    error?: Error | PostgrestError;
    source: string;
    message?: string;
    additionalInfo?: RecordItem;
    type: string;
    url: string;
  },
  supabaseClient: SupabaseClient
) => {
  if (IS_DEV_ENV) {
    console.log("@@ Error Log ", {
      error,
      source,
      message,
      additionalInfo,
      type,
      url
    });
    return;
  }
  await supabaseClient.from("error_logs").insert({
    source,
    message,
    type,
    url,
    additional_info: {
      error,
      ...additionalInfo
    }
  });
};

export const getDomainUserType = (host: string) => {
  if (host.startsWith("app.")) {
    return USER_TYPE.BUYER;
  }

  return USER_TYPE.STAFF;
};
