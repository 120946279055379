"use client";

import { useCallback, useEffect } from "react";

import useErrorLogger from "hooks/useErrorLogger";
import useCurrentUser from "hooks/useCurrentUser";

import Button from "components/Button";
import { ButtonAppearance, ERROR_TYPES } from "utils/constants";
type ErrorFallbackProps = { error: Error; resetErrorBoundary: () => void };

const ErrorFallbackComponent = (props: ErrorFallbackProps) => {
  const { logError } = useErrorLogger();
  const currentUser = useCurrentUser();

  useEffect(() => {
    props.error && console.error(props.error);
    logError({
      error: props.error,
      source: "ErrorFallbackComponent",
      message: props.error.message || "Error in ErrorFallbackComponent",
      type: ERROR_TYPES.APP_ERROR,
      url: window.location.href,
      additionalInfo: {
        stack: props.error.stack,
        currentUser
      }
    });
  }, [props.error, logError, currentUser]);

  const handleGoToHomeClick = useCallback(() => {
    if (typeof window !== "undefined") {
      window.location.href = "/table";
    }
  }, []);

  if ((props.error as any)?.digest === "NEXT_NOT_FOUND") {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-4">
        <div className="flex items-center gap-2">
          <h1 className="inline-block text-md font-semibold">404</h1>
          <h2 className="inline-block border-l border-l-black pl-2 text-sm dark:border-l-white">
            This page could not be found. Please try clearing your cookies and reload the page.
          </h2>
        </div>
        <Button
          label="Go to Home"
          appearance={ButtonAppearance.TERTIARY}
          className="!text-base-primary dark:!text-base-dark-primary"
          onClick={handleGoToHomeClick}
        />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-row items-center justify-center px-6 py-5 align-middle">
      <span>Something went wrong! Please refresh the page or contact us.</span>
      <Button label="Reset error boundary" onClick={props.resetErrorBoundary} />
    </div>
  );
};
export default ErrorFallbackComponent;
