import { useCallback } from "react";
import { PostgrestError } from "@supabase/supabase-js";

import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { RecordItem } from "types/common";
import { ERROR_TYPES } from "utils/constants";

const isLocalDev = !process.env.NEXT_PUBLIC_VERCEL_URL;

const useErrorLogger = () => {
  const supabaseClient = useSupabaseBrowser();

  const logError = useCallback(
    async ({
      error,
      source,
      message,
      additionalInfo,
      type,
      url
    }: {
      error: Error | PostgrestError;
      source: string;
      message?: string;
      additionalInfo?: RecordItem;
      type: ERROR_TYPES;
      url: string;
    }) => {
      if (isLocalDev) {
        return;
      }
      await supabaseClient.from("error_logs").insert({
        source,
        message,
        type,
        url,
        additional_info: {
          error: JSON.stringify(error),
          ...additionalInfo,
          stack: additionalInfo?.stack || (error as Error)?.stack
        }
      });
    },
    [supabaseClient]
  );

  return {
    logError
  };
};

export default useErrorLogger;
